import React, { useState } from 'react';
import {Layout, Spin, Slider, Tooltip, Collapse, Typography, Row, Col, Card, Input, Button, Checkbox } from 'antd';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import './App.css';
import Link from 'antd/es/typography/Link';

const customSpinIcon = <LoadingOutlined style={{ fontSize: 16, color: 'white' }} />;
const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { Panel } = Collapse;
const HowToUseContent = (
  <div>
    <h3>How to use</h3>
    <ul>
      <li>Visit figma.com, create an account</li>
      <li>Create a Frame with the frame tool 512x512 pixels wide (Background of an EGP)</li>
      <li>Create your shapes and keep the shape part of the name (i.e Rectangle 10, Circle SmileyBG)</li>
      <li>Right-click the frame or any elements you want to use. Press copy - copy as code - css</li>
      <li>Paste into the compiler, be happy 🙂</li>
      <li>It is recommended to use the template option for first use to run code straight from the output to EGP.</li>
    </ul>
  </div>
);

const FeaturesContent = (
  <div>
    <h3>Features</h3>
    <ul>
      <li>Flip elements: Select elements and press flip horizontally or vertically</li>
      <li>Constraints: Align to corner, side or center, and the compiler will output EGP related coordinates.</li>
      <li>Images: Place images from a PNG file that is located within the server addons, copy the path and call it Rectangle (Name) *path to image. E.G: Rectangle NextPage *materials/zerochain/zerolib/ui/icon_back.png</li>
      <li>Sections: You can group elements on Figma (right-click - group). Make sure to rename the group to Section: name. (cleans up the Figma, and adds commented section to your E2 code).</li>
    </ul>
  </div>
);

const ComingSoonContent = (
  <div>
    <h3>Coming Soon</h3>
    <ul>
      <li>Load in by Figma section: Each frame loaded separately and saved to EGP memory</li>
      <li>Animations checkbox (and template to play simple frame by frame animation)</li>
      <li>Polygons</li>
    </ul>
  </div>
);


const App = () => {
  const [loading, setLoading] = useState(false);
  const [useBufferingSystem, setUseBufferingSystem] = useState(false);
  const [useTemplateSystem, setUseTemplateSystem] = useState(true);
  const [useStringIDSystem, setUseStringIDSystem] = useState(true);
  const [useStringCheck, setStringCheck] = useState(true);
  const [bufferingAmount, setBufferingAmount] = useState('100');
  const [elementsTable, setElementsTable] = useState('Elements');

  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');

  const handleSendClick = () => {
    setLoading(true);
  
    fetch("https://egpcompiler.com/api/egp", {
      method: "POST",
      body: JSON.stringify({
        data: input,
        settings: {
          template: useTemplateSystem,
          buffering: useBufferingSystem,
          use_eid: useStringIDSystem,
          buffering_amount: bufferingAmount,
          elements_table: elementsTable
        }
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setOutput(data.output);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  return (
    <Layout className="layout" style={{ minHeight: '100vh'}}>
      <Header className="opacity" style={{ paddingLeft: '100px'}}>
        <Row style={{ justifyContent: 'center' }}>
          <Col span={12}>
            <Title level={2} style={{ color: 'white', textAlign: 'right' }}>
              Figma to E2 EGP Compiler
            </Title>
          </Col>
          <Col span={12}>
            <Text style={{ color: 'white', padding: '10px'}}>Version 0.7.6 (Beta)</Text>
          </Col>
        </Row>
      </Header>
      <Content style={{ paddingLeft: '100px', paddingRight: '100px', paddingTop: '50px', paddingBottom: '50px',  opacity:'90%' }}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Collapse accordion>
                <Panel header="Guide" key="1">
                  {HowToUseContent}
                  {FeaturesContent}
                  {ComingSoonContent}
                </Panel>
              </Collapse>
            </Col>
            <Col id='settings' xs={24} md={12} lg={12}>
              <Card title="Settings" className="settings">
                <Row gutter={[8, 16]} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '50px', paddingRight: '50px' }}>
                  <Col span={24}>
                    <Checkbox defaultChecked
                      onChange={(e) => {
                        setUseTemplateSystem(e.target.checked);
                        setUseStringIDSystem(e.target.checked);
                        setStringCheck(e.target.checked);
                      }}
                    >
                      Place in Template
                    </Checkbox>
                    <Tooltip title="Structure the code in a premade template."><InfoCircleOutlined /></Tooltip>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      onChange={(e) => setUseBufferingSystem(e.target.checked)}
                    >
                      Use Buffering System
                    </Checkbox>
                    <Tooltip title="If there are many elements, buffer the loading of elements"><InfoCircleOutlined /></Tooltip>
                  </Col>
                  {useBufferingSystem && (
                      <Col span={24}>
                        <Text>Amount  </Text><Tooltip title="The amount of elements to load in each tick."><InfoCircleOutlined /></Tooltip>
                        <Slider
                          disabled={!useBufferingSystem}
                          min={30}
                          max={100}
                          step={1}
                          defaultValue={0}
                          onChange={(value) => setBufferingAmount(value)}
                        />
                      </Col>
                    )}
                  <Col span={24}>
                    <Checkbox 
                      defaultChecked 
                      checked={useStringCheck}
                      disabled={useTemplateSystem}
                      onChange={(e) => {
                        setUseStringIDSystem(e.target.checked);
                        setStringCheck(e.target.checked);
                      }}
                      >
                      Use String ID System
                    </Checkbox>
                    <Tooltip title="Use a string ID system to call elements instead of numbers."><InfoCircleOutlined /></Tooltip>
                  </Col>
                  {useStringIDSystem && (
                      <Col span={24}>
                        <Text>Name of Table</Text>
                        <Input value={elementsTable} onChange={(e) => setElementsTable(e.target.value)} placeholder="Elements" />
                      </Col>
                    )}
                  <Col span={24}>
                    <Button onClick={() => { handleSendClick(); setLoading(true); }} style={{ width: '100%' }} type="primary">
                      Compile  {loading && (
                      <Spin style={{paddingLeft: '20px'}} indicator={customSpinIcon}></Spin>
                    )}
                    </Button>
                  </Col>
                  <Col span={24} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Text>
                      Credits: <Link target='_blank' href='https://odev.pro/'>Odin</Link> and <Link target='_blank' href='https://steamcommunity.com/id/iamthedutchman/'>Bob Tomato</Link>
                    </Text>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col id='input' xs={24} md={12}>
              <Card title="Input">
                <Input.TextArea value={input} onChange={(e) => setInput(e.target.value)} placeholder="Paste your figma css code here" />
              </Card>
            </Col>
            <Col span={24}>
              <Card title="Output">
                <Input.TextArea value={output} style={{height: '100% !important'}} placeholder="E2 code is outputted here"
                />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Text style={{color: 'white', fontSize: '40px'}}>
                <Link target='_blank' href='/changelog'>View Changelog</Link> <Link target='_blank' href='https://base.egpcompiler.com/form/Ajv7PkYHJBUr1fIHKc-7Yh0tsw7K9dwaVdI485Rj2u0'>Submit Bug Report</Link>
              </Text>
            </Col>
          </Row>
      </Content>
    </Layout>
  );
};

export default App;